import React, { Component } from 'react'
import { updateObject, checkValidity } from '../../../shared/utility'
import Form from '../../UI/Form/Form'
import axios from 'axios'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'

class EditProfile extends Component {
  state = {
    form: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Your name'
        },
        value: '',
        label: 'Name',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 13
        },
        helperText: 'Minimum 2, maximum 13 characters',
        valid: false,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@gmail.com'
        },
        value: '',
        label: 'Email',
        validation: {
          required: true,
          isEmail: true
        },
        helperText: 'Not a valid email',
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'password'
        },
        value: '',
        label: 'Password',
        validation: {
          required: true,
          minLength: 6
        },
        helperText: 'At least 6 characters',
        valid: false,
        touched: false
      },
      newPassword: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'new password'
        },
        value: '',
        label: 'New Password',
        validation: {
          minLength: 6
        },
        helperText: 'At least 6 characters',
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    loading: false,
    error: false,
    errorMessage: null,
    successMessage: null
  }

  componentDidMount() {
    const t = setInterval(() => {
      if (this.props.email) {
        clearInterval(t)

        const updatedEmail = updateObject(this.state.form.email, {
          value: this.props.email,
          valid: true,
          touched: true
        })

        const updatedName = updateObject(this.state.form.name, {
          value: this.props.name,
          valid: true,
          touched: true
        })

        const updatedForm = updateObject(this.state.form, {
          email: updatedEmail,
          name: updatedName
        })

        this.setState({
          form: updatedForm
        })
      }
    }, 10)
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updateFormElement = updateObject(this.state.form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.form[inputIdentifier].validation
      ),
      touched: true
    })

    const updatedForm = updateObject(this.state.form, {
      [inputIdentifier]: updateFormElement
    })

    let formIsValid = true

    for (let inputIdentifier in updatedForm) {
      formIsValid =
        (updatedForm[inputIdentifier].validation.required
          ? updatedForm[inputIdentifier].valid
          : true) && formIsValid
    }

    this.setState({
      form: updatedForm,
      formIsValid: formIsValid
    })
  }

  handleSubmit = () => {
    const loading = () => {
      this.setState({
        loading: true,
        error: null,
        errorMessage: null,
        successMessage: null
      })
    }

    if (this.state.form.email.value !== this.props.email) {
      loading()

      const data = {
        idToken: this.props.authToken,
        email: this.state.form.email.value,
        returnSecureToken: true
      }

      const url =
        'https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

      axios
        .post(url, data)
        .then(res => {
          this.props.onRefreshToken(res.data.refreshToken)
          this.props.onUserUpdate(
            res.data.localId,
            res.data.idToken,
            res.data.email,
            this.props.name,
            this.props.registered,
            this.props.paidAccessUntil
          )

          this.setState({
            loading: false,
            successMessage: `Email address has been updated.`
          })
        })
        .catch(err => {
          this.setState({ error: true, loading: false })

          this.setState({
            errorMessage:
              'Unfortunately something went wrong. Please try again.'
          })
        })
    }

    if (this.state.form.newPassword.valid) {
      loading()

      const data = {
        idToken: this.props.authToken,
        password: this.state.form.newPassword.value,
        returnSecureToken: true
      }

      const url =
        'https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

      axios
        .post(url, data)
        .then(res => {
          this.props.onRefreshToken(res.data.refreshToken)

          this.setState({
            loading: false,
            successMessage: `Password has been updated.`
          })
        })
        .catch(err => {
          this.setState({ error: true, loading: false })

          this.setState({
            errorMessage:
              'Unfortunately something went wrong. Please try again.'
          })
        })
    }

    if (this.state.form.name.value !== this.props.name) {
      loading()

      this.props.onUserUpdate(
        this.props.userId,
        this.props.authToken,
        this.props.email,
        this.state.form.name.value,
        this.props.registered,
        this.props.paidAccessUntil
      )

      const t = setInterval(() => {
        if (this.state.form.name.value === this.props.name) {
          clearInterval(t)

          this.setState({
            loading: false,
            successMessage: `Name has been updated.`
          })
        }
      }, 100)
    }
  }

  closeAlertHandler = () => {
    this.setState({
      successMessage: null
    })
  }

  closeErrorHandler = () => {
    this.setState({
      error: false
    })
  }

  render() {
    const formElements = []

    for (let key in this.state.form) {
      formElements.push({
        id: key,
        config: this.state.form[key]
      })
    }

    return (
      <Form
        error={this.state.error}
        closeError={this.closeErrorHandler}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        closeAlert={this.closeAlertHandler}
        title="Edit Profile"
        inputs={formElements}
        onSubmit={this.handleSubmit}
        chageHandler={this.inputChangedHandler}
        spinner={this.state.loading}
        disabled={!this.state.formIsValid}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    email: state.user.email,
    userId: state.user.id,
    name: state.user.name,
    registered: state.user.registered,
    paidAccessUntil: state.user.paidAccessUntil,
    authToken: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRefreshToken: token => dispatch(actions.refreshToken(token)),
    onUserUpdate: (userId, token, email, name, registered, paidAccessUntil) =>
      dispatch(
        actions.userUpdate(
          userId,
          token,
          email,
          name,
          registered,
          paidAccessUntil
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
