import React from 'react'
import Layout from '../hoc/Layout/Layout'
import EditProfile from '../components/Pages/EditProfile/EditProfile'
import SEO from '../components/seo'

export default () => {
  return (
    <Layout>
      <SEO
        title="Edit Profile | PMP Certification"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description={`Exam Questions to get PMP Certification. Unlimited Questions to Sharpen Your Skills with In-depth Explanations.`}
      ></SEO>
      <EditProfile />
    </Layout>
  )
}
